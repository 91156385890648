@import "../../sass/colors.scss";

.contact-section {
  margin-top: 130px;
  height: 420px;

  .section-title {
    max-width: 80%;
    margin: 0 auto 40px;
    text-align: center;
  }

  @media (max-width: 992px) {
    height: 455px;
  }

  @media (max-width: 480px) {
    height: 583px;
  }
}

.contact-form-area {
  max-width: 750px;
  margin: -200px auto 100px;
  padding: 50px;
  background: $color-white;
  border-radius: 20px;
  box-shadow: 0px 0 20px 14px #6c757d0d;
}
