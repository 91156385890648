@import '../../sass/colors.scss';

.package-included-section {
    padding-top: 100px;
    padding-bottom: 100px;
    
    .tirana-img {
        text-align: right;
        margin-right: 60px;
        position: relative;
        z-index: 1;
        margin-bottom: 30px;

        &::before {
            position: absolute;
            content: "";
            width: 45%;
            height: 125%;
            top: -12%;
            left: 35%;
            background: #DBEFFA;
            z-index: -1;
            border-radius: 20px;
        }

        img {
            width: 550px;
            height: 400px;
            object-fit: cover;
            border-radius: 16px;
        }
    }

    .package-included-text {
        max-width: 700px;
        margin-bottom: 30px;

        .section-title {
            margin-bottom: 30px;

            h2 {
                max-width: inherit;
            }
        }

        .theme-btn {
            margin-top: 80px;
        }

        .free-packages {
            padding-top: 30px;
            border-top: 1px solid rgb(182, 182, 182);
            display: grid;
            grid-template-columns: repeat(2, 1fr);
            row-gap: 1.75rem;
            column-gap: 1rem;

            .package {
                display: flex;
                align-items: center;
                gap: 1rem;
                font-size: 1.1rem;
                font-weight: 600;
                color: #181945;

                img {
                    width: 50px;
                }
            }
        }
    }
}

// Mobile Responsive
@media (max-width: 575px) {
    .package-included-section {
        padding-top: 70px;
        padding-bottom: 10px;

        .tirana-img {
            margin-right: 0;
            text-align: center;

            &::before {
                display: none;
            }
        }

        .package-included-text {
            .theme-btn {
                margin-top: 25px;
            }

            .free-packages {
                padding-left: 15%;
                display: flex;
                flex-direction: column;

                .package {
                    img {
                        width: 40px;
                    }
                }
            }
        }
    }
}
@media (min-width: 576px) and (max-width: 767px) {
    .package-included-section {
        padding-top: 50px;
        padding-bottom: 50px;

        .tirana-img {
            text-align: center;
            &::before {
                display: none;
            }
        } 

        .package-included-text {
            .theme-btn {
                margin-top: 25px;
            }
        }
    }
}

// Medium Device Responsive
@media (min-width: 768px) and (max-width: 991px) {
    .package-included-section {
        padding-top: 60px;
        padding-bottom: 40px;

        .tirana-img {
            text-align: center;
            margin-right: 0;

            &::before {
                display: none;
            }
        } 

        .package-included-text {

            .section-title {
                h2 {
                    font-size: 25px;
                }
            }

            .theme-btn {
                margin-top: 25px;
            }

            .free-packages {
                .package {
                    font-size: 14px;
                    img {
                        width: 30px;
                    }
                }
            }
        }
    }
}