@import "../../sass/colors.scss";

.team-section {
  .section-title {
    h2 {
      max-width: inherit;
    }
  }

  .team-card {
    text-align: center;
    margin-bottom: 30px;

    .team-img {
      margin-bottom: 20px;
      position: relative;

      &::before {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        width: 100%;
        height: 100%;
        background: #dbeffa;
        content: "";
        z-index: -1;
        border-radius: 10px;
      }
    }

    h3 {
      font-size: 18px;
      font-weight: 600;
    }
  }
}

.team-section {
  .container {
    .row {
      &:first-child {
        margin-bottom: 20px;
      }
    }
  }

  .scrollable-container {
    width: 100%;
    overflow-x: auto;
    white-space: nowrap;
    display: flex;
    justify-content: center;

    .scrollable-photos {
      display: flex;
      padding: 10px;

      .photo-wrapper {
        margin-right: 20px;
        flex: 0 0 auto;
        display: flex;
        flex-direction: column;
        justify-content: center;
        text-align: center;

        h5 {
          font-size: 15px;
        }

        img {
          width: 150px;
          height: 150px;
          border-radius: 50%;
          object-fit: cover;
          margin-bottom: 10px;
        }

        h3 {
          margin-top: 10px;
          text-align: center;
        }
      }
    }
  }
}

// Small Device Responsive
@media (max-width: 575px) {
  .team-section {
    .pt-5 {
      padding-top: 0 !important;
    }
  }
}
