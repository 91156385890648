.modal-dialog {
  max-width: 700px;

  .modal-header {
    &.success {
      background-color: #9fe39f;
    }

    &.error {
      background-color: #ed9e9e;
    }
  }
}
