.table-container {
  overflow-x: auto;
  max-width: 100%;
  border-radius: 10px;
}

table {
  width: 100%;
  border-collapse: collapse;
  font-family: "Montserrat", sans-serif;
}

th,
td {
  padding: 8px;
  text-align: center;
  min-width: 100px;
  background-size: 40px 40px;
  height: 55px;
}

thead th:first-child,
tbody td:first-child {
  position: sticky;
  left: 0;
  z-index: 2;
  background-color: #181945;
  color: white;
  text-align: center;
  font-weight: bold;
}

thead th:nth-child(2),
tbody td:nth-child(2) {
  position: sticky;
  top: 0;
}

tbody th,
tbody td {
  padding: 8px;
}

thead {
  padding: 8px;
}

thead {
  background-color: #181945;
}

tbody td:first-child {
  min-width: 150px;
}

tbody tr:nth-child(odd) {
  background-color: #f2f2f2;
}

@media (min-width: 390px) {
  .table-container {
    width: 350px;

    th,
    td {
      min-width: 100px;
    }
  }

  thead th,
  tbody td {
    font-size: 12px;
  }
}

@media (min-width: 768px) {
  .table-container {
    width: 600px;

    th,
    td {
      min-width: 150px;
    }
  }
}

@media (min-width: 1024px) {
  .table-container {
    width: 800px;

    th,
    td {
      min-width: 130px;
    }
  }
}

@media (min-width: 1300px) {
  .table-container {
    min-width: 1200px;

    th,
    td {
      min-width: 150px;
    }
  }

  thead th,
  tbody td {
    font-size: 18px;
  }
}
