.gallery-section {
  .section-title {
    margin: 0 auto 40px;
    max-width: 700px;

    @media (min-width: 575px) {
      max-width: unset;

      h2 {
        text-align: center;
      }
    }
  }

  img {
    border-radius: 40px;
  }

  .gallery-img {
    margin-bottom: 20px;
  }
}
