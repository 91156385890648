@import "../../sass/colors.scss";

.features-section {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2.5rem;

  h2 {
    text-align: center;
    padding: 0rem 1rem;
    font-size: 48px;
    font-weight: 700;
  }

  p {
    padding: 0rem 1rem;
    width: 85%;
  }
}

@media (max-width: 991px) {
  .features-section {
    gap: 1.5rem;

    h2 {
      font-size: 30px;
    }
  }
}

@media (max-width: 805px) {
  .features-section {
    p {
      padding: 0rem 1rem;
      width: 100%;
    }
  }
}

@media (min-width: 1400px) {
  .features-section {
    p {
      padding: 0rem 1rem;
      width: 70% !important;
    }
  }
}
