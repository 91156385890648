.privacy-policy {
  margin-top: 50px;
  padding: 50px 0;

  .container {
    margin: 0 auto;
    padding: 0 15px;
  }

  h2,
  h3 {
    margin-top: 20px;
    font-size: 20px;
    font-weight: 700;
    margin-bottom: 20px;
  }

  p {
    margin-bottom: 15px;
    font-family: "Roboto", sans-serif;
    font-weight: 700;
  }
}
